<template>
  <div>
    <div>
        <p>Do you really want to delete this user?</p>
        <p>This will delete all related table <b style="color: red">User Organisations</b> and <b style="color: red">projects</b> data.<br>
            <span style="background-color: yellow;">If you understand the consequences, you may now delete the user.</span></p>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {};
  },
  props: ["fieldAttributes", "field","result"],
};
</script>